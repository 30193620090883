import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ValidationMessageParams } from "../../components/ValidationMessageParams/ValidationMessageParams";
import { UrlParams, UrlParamsContextType } from "./interface";
import { BRANDS, LINKED_SERVER } from "../../utils/constants";

const UrlParamsContext = createContext<UrlParamsContextType>({
  params: null,
  hasParams: false,
});

export const useUrlParams = () => useContext(UrlParamsContext);

const isValidBrand = (countryParam: string, brand: string | null): boolean => {
  return BRANDS[countryParam.toLocaleLowerCase()]?.some((validBrand) =>
    brand?.includes(validBrand)
  );
};

const isValidStore = (store: string | null): boolean => {
  if (!store) return false;

  return Object.keys(LINKED_SERVER).some((validStore) =>
    store.includes(validStore)
  );
};

export const UrlParamsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [params, setParams] = useState<UrlParams | null>(null);
  const [hasParams, setHasParams] = useState(false);

  const getCountry = (brandParam: string | null): string => {
    if (brandParam?.slice(-2) === "CO") {
      return "Colombia";
    }
    if (brandParam?.slice(-2) === "EC") {
      return "Ecuador";
    }

    return "Colombia";
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const brandParam = urlParams.get("brand");
    const storeParam = urlParams.get("store");
    const countryParam = getCountry(brandParam);

    if (
      countryParam &&
      brandParam &&
      storeParam &&
      isValidStore(storeParam) &&
      isValidBrand(countryParam, brandParam)
    ) {
      setParams({
        country: countryParam,
        brand: brandParam,
        store: storeParam,
      });
      setHasParams(true);
    } else {
      setParams(null);
      setHasParams(false);
    }
  }, []);

  return (
    <UrlParamsContext.Provider value={{ params, hasParams }}>
      {hasParams ? children : <ValidationMessageParams />}
    </UrlParamsContext.Provider>
  );
};
