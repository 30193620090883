export const MESSAGE_EMAIL_ALREADY_EXISTS =
  "<h2 class='text-center'><b>Verifica los datos</b></h2> <p class='text-center mt-3'>Este correo ya se encuentra registrado.</p>";

export const MESSAGE_DATA_UPDATED =
  '<h2 class="text-center"><b>Tus datos ya se encuentran actualizados</b></h2> <p class="text-center mt-3">Sigue disfrutando de los beneficios que tenemos para ti.</p>';

export const BRANDS: Record<string, string[]> = {
  colombia: ["TNFCO", "SMCO", "JSCO", "NEWERACO", "VANSCO"],
  ecuador: ["VANSEC", "TNFEC", "SMEC", "NEWERAEC"],
};

export const TERMS_STORES_CO: Record<string, string> = {
  TNFCO: "https://www.thenorthface.com.co/terminos-y-condiciones",
  SMCO: "https://stevemadden.com.co/pages/privacy-policy",
  JSCO: "https://www.jansport.com.co/politica-de-privacidad-de-datos-personales",
  VANSCO: "https://www.vans.com.co/politicas-de-privacidad",
  NEWERACO: "https://www.neweracap.com.co/politica-de-privacidad",
};

export const TERMS_STORES_EC: Record<string, string> = {
  TNFEC: "https://www.thenorthface.com.ec/politicas-de-privacidad",
  SMEC: "https://stevemadden.com.ec/pages/privacy-policy",
  VANSEC: " https://www.vans.com.ec/politicas-de-privacidad",
  NEWERAEC:
    "https://www.neweracap.com.ec/politica-de-privacidad#DEMAS%20T%C3%89RMINOS%20LEGALES",
};

export const ID_STORES_CO: Record<string, string> = {
  TNFCO: "UZYOvxKMg",
  SMCO: "PxyEcmuNf",
  JSCO: "DHh8HLdm6",
  NEWERACO: "bcEqqiDZX",
  VANSCO: "Eg3wc9fYr",
};
export const ID_STORES_EC: Record<string, string> = {
  TNFEC: "8AILvpINo",
  SMEC: "yI10HFHgU",
  NEWERAEC: "gXQpHCcwX",
  VANSEC: "gLGnEY0zB",
};

export const LOGOS: Record<string, string> = {
  VANSCO: "/assets/images/logoVans.png",
  NEWERACO: "/assets/images/logoNewEra.png",
  VANSEC: "/assets/images/logoVans.png",
  NEWERAEC: "/assets/images/logoNewEra.png",
  TNFCO: "/assets/images/logoTnf.png",
  TNFEC: "/assets/images/logoTnf.png",
  SMEC: "/assets/images/logoStevenMadden.png",
  SMCO: "/assets/images/logoStevenMadden.png",
};

export const LINKED_SERVER: Record<string, string> = {
  VANSTumbaco: "ET1",
  VANSJardin: "EJ1",
  VansMallDelPacifico: "EM1",
  NEWERACondado: "HC1",
  NEWERAJardin: "HJ1",
  NEWERAMallDelSol: "HM1",
  NEWERAMallDelRio: "HR1",
  NEWERASanFrancisco: "HS1",
  NEWERATumbaco: "HT1",
  SMJardin: "MJ1",
  SMMallDelSol: "MM1",
  SMQuicentro: "MQ1",
  SMMallDelRio: "MR1",
  SMScala: "MS1",
  SMTumbaco: "MU1",
  TNFJardin: "SJ1",
  TNFQuicentro: "SQ1",
  TNFMallDelRio: "SR1",
  TNFScala: "SS1",
  TNFTumbaco: "ST1",
};

export const TYPE_IDS_CO = [
  { label: "CC", value: "CC" },
  { label: "CE", value: "CE" },
  { label: "TI", value: "TI" },
  { label: "Pasaporte", value: "Pasaporte" },
];
export const TYPE_IDS_EC = [
  { label: "CI", value: "CI" },
  { label: "RUC", value: "RUC" },
  { label: "Pasaporte", value: "Pasaporte" },
];

export const COUNTRIES = [
  {
    id: 144,
    name: "Afganistán",
    label: "Afganistán",
    value: "Afganistán",
  },
  {
    id: 114,
    name: "Albania",
    label: "Albania",
    value: "Albania",
  },
  {
    id: 18,
    name: "Alemania",
    label: "Alemania",
    value: "Alemania",
  },
  {
    id: 98,
    name: "Algeria",
    label: "Algeria",
    value: "Algeria",
  },
  {
    id: 145,
    name: "Andorra",
    label: "Andorra",
    value: "Andorra",
  },
  {
    id: 119,
    name: "Angola",
    label: "Angola",
    value: "Angola",
  },
  {
    id: 4,
    name: "Anguilla",
    label: "Anguilla",
    value: "Anguilla",
  },
  {
    id: 147,
    name: "Antigua y Barbuda",
    label: "Antigua y Barbuda",
    value: "Antigua y Barbuda",
  },
  {
    id: 207,
    name: "Antillas Holandesas",
    label: "Antillas Holandesas",
    value: "Antillas Holandesas",
  },
  {
    id: 91,
    name: "Arabia Saudita",
    label: "Arabia Saudita",
    value: "Arabia Saudita",
  },
  {
    id: 5,
    name: "Argentina",
    label: "Argentina",
    value: "Argentina",
  },
  {
    id: 6,
    name: "Armenia",
    label: "Armenia",
    value: "Armenia",
  },
  {
    id: 142,
    name: "Aruba",
    label: "Aruba",
    value: "Aruba",
  },
  {
    id: 1,
    name: "Australia",
    label: "Australia",
    value: "Australia",
  },
  {
    id: 2,
    name: "Austria",
    label: "Austria",
    value: "Austria",
  },
  {
    id: 3,
    name: "Azerbaiyán",
    label: "Azerbaiyán",
    value: "Azerbaiyán",
  },
  {
    id: 80,
    name: "Bahamas",
    label: "Bahamas",
    value: "Bahamas",
  },
  {
    id: 127,
    name: "Bahrein",
    label: "Bahrein",
    value: "Bahrein",
  },
  {
    id: 149,
    name: "Bangladesh",
    label: "Bangladesh",
    value: "Bangladesh",
  },
  {
    id: 128,
    name: "Barbados",
    label: "Barbados",
    value: "Barbados",
  },
  {
    id: 9,
    name: "Bélgica",
    label: "Bélgica",
    value: "Bélgica",
  },
  {
    id: 8,
    name: "Belice",
    label: "Belice",
    value: "Belice",
  },
  {
    id: 151,
    name: "Benín",
    label: "Benín",
    value: "Benín",
  },
  {
    id: 10,
    name: "Bermudas",
    label: "Bermudas",
    value: "Bermudas",
  },
  {
    id: 7,
    name: "Bielorrusia",
    label: "Bielorrusia",
    value: "Bielorrusia",
  },
  {
    id: 123,
    name: "Bolivia",
    label: "Bolivia",
    value: "Bolivia",
  },
  {
    id: 79,
    name: "Bosnia y Herzegovina",
    label: "Bosnia y Herzegovina",
    value: "Bosnia y Herzegovina",
  },
  {
    id: 100,
    name: "Botsuana",
    label: "Botsuana",
    value: "Botsuana",
  },
  {
    id: 12,
    name: "Brasil",
    label: "Brasil",
    value: "Brasil",
  },
  {
    id: 155,
    name: "Brunéi",
    label: "Brunéi",
    value: "Brunéi",
  },
  {
    id: 11,
    name: "Bulgaria",
    label: "Bulgaria",
    value: "Bulgaria",
  },
  {
    id: 156,
    name: "Burkina Faso",
    label: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    id: 157,
    name: "Burundi",
    label: "Burundi",
    value: "Burundi",
  },
  {
    id: 152,
    name: "Bután",
    label: "Bután",
    value: "Bután",
  },
  {
    id: 159,
    name: "Cabo Verde",
    label: "Cabo Verde",
    value: "Cabo Verde",
  },
  {
    id: 158,
    name: "Camboya",
    label: "Camboya",
    value: "Camboya",
  },
  {
    id: 31,
    name: "Camerún",
    label: "Camerún",
    value: "Camerún",
  },
  {
    id: 32,
    name: "Canadá",
    label: "Canadá",
    value: "Canadá",
  },
  {
    id: 130,
    name: "Chad",
    label: "Chad",
    value: "Chad",
  },
  {
    id: 81,
    name: "Chile",
    label: "Chile",
    value: "Chile",
  },
  {
    id: 35,
    name: "China",
    label: "China",
    value: "China",
  },
  {
    id: 33,
    name: "Chipre",
    label: "Chipre",
    value: "Chipre",
  },
  {
    id: 82,
    name: "Colombia",
    label: "Colombia",
    value: "Colombia",
  },
  {
    id: 164,
    name: "Comores",
    label: "Comores",
    value: "Comores",
  },
  {
    id: 112,
    name: "Congo (Brazzaville)",
    label: "Congo (Brazzaville)",
    value: "Congo (Brazzaville)",
  },
  {
    id: 165,
    name: "Congo (Kinshasa)",
    label: "Congo (Kinshasa)",
    value: "Congo (Kinshasa)",
  },
  {
    id: 166,
    name: "Cook, Islas",
    label: "Cook, Islas",
    value: "Cook, Islas",
  },
  {
    id: 84,
    name: "Corea del Norte",
    label: "Corea del Norte",
    value: "Corea del Norte",
  },
  {
    id: 69,
    name: "Corea del Sur",
    label: "Corea del Sur",
    value: "Corea del Sur",
  },
  {
    id: 168,
    name: "Costa de Marfil",
    label: "Costa de Marfil",
    value: "Costa de Marfil",
  },
  {
    id: 36,
    name: "Costa Rica",
    label: "Costa Rica",
    value: "Costa Rica",
  },
  {
    id: 71,
    name: "Croacia",
    label: "Croacia",
    value: "Croacia",
  },
  {
    id: 113,
    name: "Cuba",
    label: "Cuba",
    value: "Cuba",
  },
  {
    id: 22,
    name: "Dinamarca",
    label: "Dinamarca",
    value: "Dinamarca",
  },
  {
    id: 169,
    name: "Djibouti, Yibuti",
    label: "Djibouti, Yibuti",
    value: "Djibouti, Yibuti",
  },
  {
    id: 103,
    name: "Ecuador",
    label: "Ecuador",
    value: "Ecuador",
  },
  {
    id: 23,
    name: "Egipto",
    label: "Egipto",
    value: "Egipto",
  },
  {
    id: 51,
    name: "El Salvador",
    label: "El Salvador",
    value: "El Salvador",
  },
  {
    id: 93,
    name: "Emiratos Árabes Unidos",
    label: "Emiratos Árabes Unidos",
    value: "Emiratos Árabes Unidos",
  },
  {
    id: 173,
    name: "Eritrea",
    label: "Eritrea",
    value: "Eritrea",
  },
  {
    id: 52,
    name: "Eslovaquia",
    label: "Eslovaquia",
    value: "Eslovaquia",
  },
  {
    id: 53,
    name: "Eslovenia",
    label: "Eslovenia",
    value: "Eslovenia",
  },
  {
    id: 28,
    name: "España",
    label: "España",
    value: "España",
  },
  {
    id: 55,
    name: "Estados Unidos",
    label: "Estados Unidos",
    value: "Estados Unidos",
  },
  {
    id: 68,
    name: "Estonia",
    label: "Estonia",
    value: "Estonia",
  },
  {
    id: 121,
    name: "Etiopía",
    label: "Etiopía",
    value: "Etiopía",
  },
  {
    id: 175,
    name: "Feroe, Islas",
    label: "Feroe, Islas",
    value: "Feroe, Islas",
  },
  {
    id: 90,
    name: "Filipinas",
    label: "Filipinas",
    value: "Filipinas",
  },
  {
    id: 63,
    name: "Finlandia",
    label: "Finlandia",
    value: "Finlandia",
  },
  {
    id: 176,
    name: "Fiyi",
    label: "Fiyi",
    value: "Fiyi",
  },
  {
    id: 64,
    name: "Francia",
    label: "Francia",
    value: "Francia",
  },
  {
    id: 180,
    name: "Gabón",
    label: "Gabón",
    value: "Gabón",
  },
  {
    id: 181,
    name: "Gambia",
    label: "Gambia",
    value: "Gambia",
  },
  {
    id: 21,
    name: "Georgia",
    label: "Georgia",
    value: "Georgia",
  },
  {
    id: 105,
    name: "Ghana",
    label: "Ghana",
    value: "Ghana",
  },
  {
    id: 143,
    name: "Gibraltar",
    label: "Gibraltar",
    value: "Gibraltar",
  },
  {
    id: 184,
    name: "Granada",
    label: "Granada",
    value: "Granada",
  },
  {
    id: 20,
    name: "Grecia",
    label: "Grecia",
    value: "Grecia",
  },
  {
    id: 94,
    name: "Groenlandia",
    label: "Groenlandia",
    value: "Groenlandia",
  },
  {
    id: 17,
    name: "Guadalupe",
    label: "Guadalupe",
    value: "Guadalupe",
  },
  {
    id: 185,
    name: "Guatemala",
    label: "Guatemala",
    value: "Guatemala",
  },
  {
    id: 186,
    name: "Guernsey",
    label: "Guernsey",
    value: "Guernsey",
  },
  {
    id: 187,
    name: "Guinea",
    label: "Guinea",
    value: "Guinea",
  },
  {
    id: 172,
    name: "Guinea Ecuatorial",
    label: "Guinea Ecuatorial",
    value: "Guinea Ecuatorial",
  },
  {
    id: 188,
    name: "Guinea-Bissau",
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    id: 189,
    name: "Guyana",
    label: "Guyana",
    value: "Guyana",
  },
  {
    id: 16,
    name: "Haiti",
    label: "Haiti",
    value: "Haiti",
  },
  {
    id: 137,
    name: "Honduras",
    label: "Honduras",
    value: "Honduras",
  },
  {
    id: 73,
    name: "Hong Kong",
    label: "Hong Kong",
    value: "Hong Kong",
  },
  {
    id: 14,
    name: "Hungría",
    label: "Hungría",
    value: "Hungría",
  },
  {
    id: 25,
    name: "India",
    label: "India",
    value: "India",
  },
  {
    id: 74,
    name: "Indonesia",
    label: "Indonesia",
    value: "Indonesia",
  },
  {
    id: 140,
    name: "Irak",
    label: "Irak",
    value: "Irak",
  },
  {
    id: 26,
    name: "Irán",
    label: "Irán",
    value: "Irán",
  },
  {
    id: 27,
    name: "Irlanda",
    label: "Irlanda",
    value: "Irlanda",
  },
  {
    id: 215,
    name: "Isla Pitcairn",
    label: "Isla Pitcairn",
    value: "Isla Pitcairn",
  },
  {
    id: 83,
    name: "Islandia",
    label: "Islandia",
    value: "Islandia",
  },
  {
    id: 228,
    name: "Islas Salomón",
    label: "Islas Salomón",
    value: "Islas Salomón",
  },
  {
    id: 58,
    name: "Islas Turcas y Caicos",
    label: "Islas Turcas y Caicos",
    value: "Islas Turcas y Caicos",
  },
  {
    id: 154,
    name: "Islas Virgenes Británicas",
    label: "Islas Virgenes Británicas",
    value: "Islas Virgenes Británicas",
  },
  {
    id: 24,
    name: "Israel",
    label: "Israel",
    value: "Israel",
  },
  {
    id: 29,
    name: "Italia",
    label: "Italia",
    value: "Italia",
  },
  {
    id: 132,
    name: "Jamaica",
    label: "Jamaica",
    value: "Jamaica",
  },
  {
    id: 70,
    name: "Japón",
    label: "Japón",
    value: "Japón",
  },
  {
    id: 193,
    name: "Jersey",
    label: "Jersey",
    value: "Jersey",
  },
  {
    id: 75,
    name: "Jordania",
    label: "Jordania",
    value: "Jordania",
  },
  {
    id: 30,
    name: "Kazajstán",
    label: "Kazajstán",
    value: "Kazajstán",
  },
  {
    id: 97,
    name: "Kenia",
    label: "Kenia",
    value: "Kenia",
  },
  {
    id: 34,
    name: "Kirguistán",
    label: "Kirguistán",
    value: "Kirguistán",
  },
  {
    id: 195,
    name: "Kiribati",
    label: "Kiribati",
    value: "Kiribati",
  },
  {
    id: 37,
    name: "Kuwait",
    label: "Kuwait",
    value: "Kuwait",
  },
  {
    id: 196,
    name: "Laos",
    label: "Laos",
    value: "Laos",
  },
  {
    id: 197,
    name: "Lesotho",
    label: "Lesotho",
    value: "Lesotho",
  },
  {
    id: 38,
    name: "Letonia",
    label: "Letonia",
    value: "Letonia",
  },
  {
    id: 99,
    name: "Líbano",
    label: "Líbano",
    value: "Líbano",
  },
  {
    id: 198,
    name: "Liberia",
    label: "Liberia",
    value: "Liberia",
  },
  {
    id: 39,
    name: "Libia",
    label: "Libia",
    value: "Libia",
  },
  {
    id: 126,
    name: "Liechtenstein",
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    id: 40,
    name: "Lituania",
    label: "Lituania",
    value: "Lituania",
  },
  {
    id: 41,
    name: "Luxemburgo",
    label: "Luxemburgo",
    value: "Luxemburgo",
  },
  {
    id: 85,
    name: "Macedonia",
    label: "Macedonia",
    value: "Macedonia",
  },
  {
    id: 134,
    name: "Madagascar",
    label: "Madagascar",
    value: "Madagascar",
  },
  {
    id: 76,
    name: "Malasia",
    label: "Malasia",
    value: "Malasia",
  },
  {
    id: 125,
    name: "Malawi",
    label: "Malawi",
    value: "Malawi",
  },
  {
    id: 200,
    name: "Maldivas",
    label: "Maldivas",
    value: "Maldivas",
  },
  {
    id: 133,
    name: "Malí",
    label: "Malí",
    value: "Malí",
  },
  {
    id: 86,
    name: "Malta",
    label: "Malta",
    value: "Malta",
  },
  {
    id: 131,
    name: "Man, Isla de",
    label: "Man, Isla de",
    value: "Man, Isla de",
  },
  {
    id: 104,
    name: "Marruecos",
    label: "Marruecos",
    value: "Marruecos",
  },
  {
    id: 201,
    name: "Martinica",
    label: "Martinica",
    value: "Martinica",
  },
  {
    id: 202,
    name: "Mauricio",
    label: "Mauricio",
    value: "Mauricio",
  },
  {
    id: 108,
    name: "Mauritania",
    label: "Mauritania",
    value: "Mauritania",
  },
  {
    id: 42,
    name: "México",
    label: "México",
    value: "México",
  },
  {
    id: 43,
    name: "Moldavia",
    label: "Moldavia",
    value: "Moldavia",
  },
  {
    id: 44,
    name: "Mónaco",
    label: "Mónaco",
    value: "Mónaco",
  },
  {
    id: 139,
    name: "Mongolia",
    label: "Mongolia",
    value: "Mongolia",
  },
  {
    id: 117,
    name: "Mozambique",
    label: "Mozambique",
    value: "Mozambique",
  },
  {
    id: 205,
    name: "Myanmar",
    label: "Myanmar",
    value: "Myanmar",
  },
  {
    id: 102,
    name: "Namibia",
    label: "Namibia",
    value: "Namibia",
  },
  {
    id: 206,
    name: "Nauru",
    label: "Nauru",
    value: "Nauru",
  },
  {
    id: 107,
    name: "Nepal",
    label: "Nepal",
    value: "Nepal",
  },
  {
    id: 209,
    name: "Nicaragua",
    label: "Nicaragua",
    value: "Nicaragua",
  },
  {
    id: 210,
    name: "Níger",
    label: "Níger",
    value: "Níger",
  },
  {
    id: 115,
    name: "Nigeria",
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    id: 212,
    name: "Norfolk Island",
    label: "Norfolk Island",
    value: "Norfolk Island",
  },
  {
    id: 46,
    name: "Noruega",
    label: "Noruega",
    value: "Noruega",
  },
  {
    id: 208,
    name: "Nueva Caledonia",
    label: "Nueva Caledonia",
    value: "Nueva Caledonia",
  },
  {
    id: 45,
    name: "Nueva Zelanda",
    label: "Nueva Zelanda",
    value: "Nueva Zelanda",
  },
  {
    id: 213,
    name: "Omán",
    label: "Omán",
    value: "Omán",
  },
  {
    id: 19,
    name: "Países Bajos, Holanda",
    label: "Países Bajos, Holanda",
    value: "Países Bajos, Holanda",
  },
  {
    id: 87,
    name: "Pakistán",
    label: "Pakistán",
    value: "Pakistán",
  },
  {
    id: 124,
    name: "Panamá",
    label: "Panamá",
    value: "Panamá",
  },
  {
    id: 88,
    name: "Papúa-Nueva Guinea",
    label: "Papúa-Nueva Guinea",
    value: "Papúa-Nueva Guinea",
  },
  {
    id: 110,
    name: "Paraguay",
    label: "Paraguay",
    value: "Paraguay",
  },
  {
    id: 89,
    name: "Perú",
    label: "Perú",
    value: "Perú",
  },
  {
    id: 178,
    name: "Polinesia Francesa",
    label: "Polinesia Francesa",
    value: "Polinesia Francesa",
  },
  {
    id: 47,
    name: "Polonia",
    label: "Polonia",
    value: "Polonia",
  },
  {
    id: 48,
    name: "Portugal",
    label: "Portugal",
    value: "Portugal",
  },
  {
    id: 246,
    name: "Puerto Rico",
    label: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    id: 216,
    name: "Qatar",
    label: "Qatar",
    value: "Qatar",
  },
  {
    id: 13,
    name: "Reino Unido",
    label: "Reino Unido",
    value: "Reino Unido",
  },
  {
    id: 65,
    name: "República Checa",
    label: "República Checa",
    value: "República Checa",
  },
  {
    id: 138,
    name: "República Dominicana",
    label: "República Dominicana",
    value: "República Dominicana",
  },
  {
    id: 49,
    name: "Reunión",
    label: "Reunión",
    value: "Reunión",
  },
  {
    id: 217,
    name: "Ruanda",
    label: "Ruanda",
    value: "Ruanda",
  },
  {
    id: 72,
    name: "Rumanía",
    label: "Rumanía",
    value: "Rumanía",
  },
  {
    id: 50,
    name: "Rusia",
    label: "Rusia",
    value: "Rusia",
  },
  {
    id: 242,
    name: "Sáhara Occidental",
    label: "Sáhara Occidental",
    value: "Sáhara Occidental",
  },
  {
    id: 223,
    name: "Samoa",
    label: "Samoa",
    value: "Samoa",
  },
  {
    id: 219,
    name: "San Cristobal y Nevis",
    label: "San Cristobal y Nevis",
    value: "San Cristobal y Nevis",
  },
  {
    id: 224,
    name: "San Marino",
    label: "San Marino",
    value: "San Marino",
  },
  {
    id: 221,
    name: "San Pedro y Miquelón",
    label: "San Pedro y Miquelón",
    value: "San Pedro y Miquelón",
  },
  {
    id: 225,
    name: "San Tomé y Príncipe",
    label: "San Tomé y Príncipe",
    value: "San Tomé y Príncipe",
  },
  {
    id: 222,
    name: "San Vincente y Granadinas",
    label: "San Vincente y Granadinas",
    value: "San Vincente y Granadinas",
  },
  {
    id: 218,
    name: "Santa Elena",
    label: "Santa Elena",
    value: "Santa Elena",
  },
  {
    id: 220,
    name: "Santa Lucía",
    label: "Santa Lucía",
    value: "Santa Lucía",
  },
  {
    id: 135,
    name: "Senegal",
    label: "Senegal",
    value: "Senegal",
  },
  {
    id: 226,
    name: "Serbia y Montenegro",
    label: "Serbia y Montenegro",
    value: "Serbia y Montenegro",
  },
  {
    id: 109,
    name: "Seychelles",
    label: "Seychelles",
    value: "Seychelles",
  },
  {
    id: 227,
    name: "Sierra Leona",
    label: "Sierra Leona",
    value: "Sierra Leona",
  },
  {
    id: 77,
    name: "Singapur",
    label: "Singapur",
    value: "Singapur",
  },
  {
    id: 106,
    name: "Siria",
    label: "Siria",
    value: "Siria",
  },
  {
    id: 229,
    name: "Somalia",
    label: "Somalia",
    value: "Somalia",
  },
  {
    id: 120,
    name: "Sri Lanka",
    label: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    id: 141,
    name: "Sudáfrica",
    label: "Sudáfrica",
    value: "Sudáfrica",
  },
  {
    id: 232,
    name: "Sudán",
    label: "Sudán",
    value: "Sudán",
  },
  {
    id: 67,
    name: "Suecia",
    label: "Suecia",
    value: "Suecia",
  },
  {
    id: 66,
    name: "Suiza",
    label: "Suiza",
    value: "Suiza",
  },
  {
    id: 54,
    name: "Surinam",
    label: "Surinam",
    value: "Surinam",
  },
  {
    id: 234,
    name: "Swazilandia",
    label: "Swazilandia",
    value: "Swazilandia",
  },
  {
    id: 56,
    name: "Tadjikistan",
    label: "Tadjikistan",
    value: "Tadjikistan",
  },
  {
    id: 92,
    name: "Tailandia",
    label: "Tailandia",
    value: "Tailandia",
  },
  {
    id: 78,
    name: "Taiwan",
    label: "Taiwan",
    value: "Taiwan",
  },
  {
    id: 101,
    name: "Tanzania",
    label: "Tanzania",
    value: "Tanzania",
  },
  {
    id: 171,
    name: "Timor Oriental",
    label: "Timor Oriental",
    value: "Timor Oriental",
  },
  {
    id: 136,
    name: "Togo",
    label: "Togo",
    value: "Togo",
  },
  {
    id: 235,
    name: "Tokelau",
    label: "Tokelau",
    value: "Tokelau",
  },
  {
    id: 236,
    name: "Tonga",
    label: "Tonga",
    value: "Tonga",
  },
  {
    id: 237,
    name: "Trinidad y Tobago",
    label: "Trinidad y Tobago",
    value: "Trinidad y Tobago",
  },
  {
    id: 122,
    name: "Túnez",
    label: "Túnez",
    value: "Túnez",
  },
  {
    id: 57,
    name: "Turkmenistan",
    label: "Turkmenistan",
    value: "Turkmenistan",
  },
  {
    id: 59,
    name: "Turquía",
    label: "Turquía",
    value: "Turquía",
  },
  {
    id: 239,
    name: "Tuvalu",
    label: "Tuvalu",
    value: "Tuvalu",
  },
  {
    id: 62,
    name: "Ucrania",
    label: "Ucrania",
    value: "Ucrania",
  },
  {
    id: 60,
    name: "Uganda",
    label: "Uganda",
    value: "Uganda",
  },
  {
    id: 111,
    name: "Uruguay",
    label: "Uruguay",
    value: "Uruguay",
  },
  {
    id: 61,
    name: "Uzbekistán",
    label: "Uzbekistán",
    value: "Uzbekistán",
  },
  {
    id: 240,
    name: "Vanuatu",
    label: "Vanuatu",
    value: "Vanuatu",
  },
  {
    id: 95,
    name: "Venezuela",
    label: "Venezuela",
    value: "Venezuela",
  },
  {
    id: 15,
    name: "Vietnam",
    label: "Vietnam",
    value: "Vietnam",
  },
  {
    id: 241,
    name: "Wallis y Futuna",
    label: "Wallis y Futuna",
    value: "Wallis y Futuna",
  },
  {
    id: 243,
    name: "Yemen",
    label: "Yemen",
    value: "Yemen",
  },
  {
    id: 116,
    name: "Zambia",
    label: "Zambia",
    value: "Zambia",
  },
  {
    id: 96,
    name: "Zimbabwe",
    label: "Zimbabwe",
    value: "Zimbabwe",
  },
];

export const customStyles = (isInvalid: boolean) => ({
  control: (provided: any) => ({
    ...provided,
    borderColor: isInvalid ? "#dc3545" : "#dee2e6",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      borderColor: "none",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "black",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#1c3d80" : "white",
    color: state.isFocused ? "white" : "black",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: isInvalid ? "#dc3545" : "#ccc",
  }),
});
